import { getServerBaseUrl, getWsBaseUrl } from '@/functions'

const server = window.electron
  ? 'meet.temeno.net' // on Electron, use huddlenow cloud server
  : location.hostname + (location.port ? (':' + location.port) : '') // on web, use hostname:port

export default {
  namespaced: true,
  state: () => ({
    connected: false,
    server,
    workspace: null,
  }),
  mutations: {
    connected(state, connected) {
      state.connected = connected
    },
    setServer(state, server) {
      state.server = server
    },
    setWorkspace(state, workspace) {
      state.workspace = workspace
    },
  },
  actions: {
    setServer({ commit }, server) {
      commit('setServer', server)
      commit('connected', false)
    },
    setWorkspace({ commit }, workspace) {
      commit('setWorkspace', workspace)
      commit('connected', false)
    },
  },
  getters: {
    isServerConfigured: (state) => {
      const { server } = state
      return server != null
    },
    serverBaseUrl: (state) => {
      const { server } = state
      if (server) {
        return getServerBaseUrl(server)
      }
    },
    wsUrl: (state) => {
      const { server } = state
      if (server) {
        return getWsBaseUrl(server)
      }
    },
  },
}
