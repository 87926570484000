<template>
  <v-main>
    <v-card>
      <v-card-title>
        Externe Kontakte

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          class="mr-5"
          clearable
          clear-icon="$delete"
          append-icon="$search"
          label="Kontakt suchen"
          single-line
          hide-details
        ></v-text-field>

        <v-spacer></v-spacer>

        <temeno-circle-button
          class="mt-3"
          title="Kontakt erstellen"
          @click="
            contactEditor.contact = {};
            contactEditor.show = true;
          "
        >
          $create_contact
        </temeno-circle-button>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :footer-props="{ itemsPerPageText: 'Kontakte pro Seite:' }"
          :headers="headers"
          :items="contacts"
          :items-per-page="10"
          :loading="loading"
          no-results-text="Keine passenden Kontakte"
          :search="search"
          sort-by="name"
        >
          <template v-slot:item.actions="{ item }">
            <temeno-circle-button title="bearbeiten" @click="edit(item)">
              $edit
            </temeno-circle-button>

            <temeno-circle-button title="löschen" @click="remove(item)">
              $delete
            </temeno-circle-button>
          </template>
          <template v-slot:no-data>
            <v-container class="pa-16">
              <v-icon class="mb-6" x-large>$participants</v-icon><br />

              Hier können Sie Ihre persönlichen externen Kontakte pflegen.<br />

              <temeno-button
                class="mt-8"
                @click="
                  contactEditor.contact = {};
                  contactEditor.show = true;
                "
              >
                <v-icon left>$create_contact</v-icon>
                Kontakt erstellen
              </temeno-button>
            </v-container>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <contact-editor
      v-model="contactEditor.contact"
      :show="contactEditor.show"
      @cancel="contactEditor.show = false"
      @input="onContactEditorInput"
    ></contact-editor>
  </v-main>
</template>

<script>
import api from "@/api";

export default {
  name: "Contacts",
  components: {
    ContactEditor: () => import("@/components/ContactEditor"),
    TemenoButton: () => import("@/components/TemenoButton"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  data() {
    return {
      contactEditor: {
        contact: null,
        show: false,
      },
      contacts: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "E-Mail-Adresse", value: "email" },
        { text: "Aktionen", value: "actions", sortable: false },
      ],
      loading: false,
      search: "",
    };
  },
  mounted() {
    this.loadContacts();
  },
  methods: {
    loadContacts() {
      this.loading = true;
      api
        .listContacts()
        .then((response) => {
          this.contacts = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    edit(contact) {
      this.contactEditor.contact = contact;
      this.contactEditor.show = true;
    },
    onContactEditorInput(contact) {
      if (contact.id) {
        api.updateContact(contact).then(() => {
          this.loadContacts();
          this.contactEditor.show = false;
        });
      } else {
        api.createContact(contact).then(() => {
          this.loadContacts();
          this.contactEditor.show = false;
        });
      }
    },
    remove(contact) {
      const { id } = contact;
      api.deleteContact(id).then(() => {
        this.loadContacts();
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.theme--dark {
  &.v-data-table, &.v-sheet {
    background-color: $color-background;  
  }
}
</style>