import router from '@/router'
import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    // conference IDs are added as keys
  }),
  mutations: {
    add(state, invitation) {
      console.log('adding invitation', invitation)
      const { id } = invitation.conference
      Vue.set(state, id, invitation)
    },
    remove(state, invitation) {
      console.log('removing invitation', invitation)
      const { id } = invitation.conference
      Vue.delete(state, id)
    },
  },
  actions: {
    add({ commit }, invitation) {
      commit('add', invitation)
      if (window.electron) {
        window.electron.ipcRenderer.send("new-invitation", invitation)
      } 
    },
    accept({ commit }, invitation) {
      console.log('accepted invitation', invitation)
      router.push({
        name: 'conference',
        params: {
          id: invitation.conference.id,
        },
      })
      commit('remove', invitation)
      // TODO send info to server
    },
    decline({ commit }, invitation) {
      console.log('declined invitation', invitation)
      commit('remove', invitation)
      // TODO send info to server
    },
  },
  getters: {
    count: (state) => Object.keys(state).length,
  },
}
