import { v4 as uuidv4 } from "uuid";

function process(item, icon) {
  if (typeof item === 'string') {
    item = { message: item }
  }
  item.id = uuidv4()
  if (!item.color) {
    item.color = "#14415f"
  }
  if (!item.icon) {
    item.icon = icon
  }
  if (!item.timeout) {
    item.timeout = 5000
  }
  return item
}

export default {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  mutations: {
    add(state, item) {
      state.items.push(item)
    },
    remove(state, id) {
      const index = state.items.findIndex((item) => item.id === id)
      if (index !== -1) {
        state.items.splice(index, 1)
      }
    },
  },
  actions: {
    addMail: ({ commit }, item) => {
      commit("add", process(item, "$mail"))
    },
    addInfo: ({ commit }, item) => {
      commit("add", process(item, "$information"))
    },
    addSuccess: ({ commit }, item) => {
      commit("add", process(item, "$approve"))
    },
    addWarning: ({ commit }, item) => {
      commit("add", process(item, "$alarm"))
    },
    addError: ({ commit }, item) => {
      commit("add", process(item, "$alert"))
    },
  },
}

/*

this.$store.dispatch("snackbar/addMail", {
      message: "a short <strong>custom HTML</strong> message",
      timeout: 1000,
    });
this.$store.dispatch("snackbar/addInfo", "INFO");
this.$store.dispatch("snackbar/addSuccess", "SUCCESS");
this.$store.dispatch("snackbar/addWarning", "WARNING");
this.$store.dispatch("snackbar/addError", "ERROR");

*/
