import { AVAILABLE, IDLE } from '@/status'
import jwt from 'jsonwebtoken'
import api from '@/api'

const PUBLISH_STATUS_INTERVAL = 30000 // ms

export default {
  namespaced: true,
  state: () => ({
    loggedIn: false,
    accessToken: null,
    refreshToken: null,
    id: null,
    showName: null,
    email: null,
    workingTimes: {
      timeZone: null,
      from: null,
      to: null,
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
    roles: [],
    avatar: {
      type: null,
      url: null,
    },
    avatarLastChanged: null,
    status: IDLE,
    statusIntervalId: null,
    showDownloadAd: true,
  }),
  mutations: {
    loggedIn(state) {
      state.loggedIn = true
    },
    loggedOut(state) {
      state.loggedIn = false
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken
      if (accessToken) {
        const decoded = jwt.decode(accessToken)
        state.id = decoded.sub
        state.showName = decoded.showName
        state.email = decoded.email
        state.roles = decoded.realm_access.roles
      } else {
        state.id = null
        state.showName = null
        state.email = null
        state.roles = []
      }
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
    },
    setStatus(state, status) {
      state.status = status
    },
    setStatusIntervalId(state, statusIntervalId) {
      state.statusIntervalId = statusIntervalId
    },
    setAvatar(state, avatar) {
      state.avatar = avatar
    },
    setAvatarChanged(state) {
      state.avatarLastChanged = new Date().getTime()
    },
    setWorkingTimes(state, workingTimes) {
      state.workingTimes = workingTimes
    },
    dismissDownloadAd(state) {
      state.showDownloadAd = false
    },
  },
  actions: {
    async login(context, responseData) {
      context.commit('setAccessToken', responseData.access_token)
      context.commit('setRefreshToken', responseData.refresh_token)
      const { avatar, workingTimes } = (await api.getUsersMe()).data
      context.commit('setAvatar', avatar)
      context.commit('setAvatarChanged')
      context.commit('setWorkingTimes', workingTimes)
      context.commit('loggedIn')
      context.dispatch('setStatus', AVAILABLE)
    },
    logout(context) {
      context.dispatch('setStatus', IDLE)
      context.commit('setAccessToken', null)
      context.commit('setRefreshToken', null)
      context.commit('loggedOut')
    },
    setStatus(context, status) {
      console.log('setting user status to ' + status);
      context.commit('setStatus', status)
      if (context.state.statusIntervalId) {
        clearInterval(context.state.statusIntervalId)
      }
      api.publishStatus(status)
      const intervalId = setInterval(() => {
        api.publishStatus(status)
      }, PUBLISH_STATUS_INTERVAL)
      context.commit('setStatusIntervalId', intervalId)
    },
  },
  getters: {
    isWorkspaceAdmin: (state) => state.roles.includes('workspace_admin'),
  },
}
