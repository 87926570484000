import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      alarm: {
        component: () => import("@/components/icons/AlarmIcon"),
      },
      alert: {
        component: () => import("@/components/icons/AlertIcon"),
      },
      approve: {
        component: () => import("@/components/icons/ApproveIcon"),
      },
      approve_action_disabled: {
        component: () => import("@/components/icons/ApproveActionDisabledIcon"),
      },
      arrow_left: {
        component: () => import("@/components/icons/ArrowLeftIcon"),
      },
      arrow_right: {
        component: () => import("@/components/icons/ArrowRightIcon"),
      },
      calendar: {
        component: () => import("@/components/icons/CalendarIcon"),
      },
      call: {
        component: () => import("@/components/icons/CallIcon"),
      },
      call_end: {
        component: () => import("@/components/icons/CallEndIcon"),
      },
      close: {
        component: () => import("@/components/icons/CloseIcon"),
      },
      close_red: {
        component: () => import("@/components/icons/CloseRedIcon"),
      },
      contacts: {
        component: () => import("@/components/icons/ContactsIcon"),
      },
      copy: {
        component: () => import("@/components/icons/CopyIcon"),
      },
      create_contact: {
        component: () => import("@/components/icons/CreateContactIcon"),
      },
      delete: {
        component: () => import("@/components/icons/DeleteIcon"),
      },
      download: {
        component: () => import("@/components/icons/DownloadIcon"),
      },
      edit: {
        component: () => import("@/components/icons/EditIcon"),
      },
      eye: {
        component: () => import("@/components/icons/EyeIcon"),
      },
      eye_off: {
        component: () => import("@/components/icons/EyeOffIcon"),
      },
      favorite_green: {
        component: () => import("@/components/icons/FavoriteGreenIcon"),
      },
      favorite_white: {
        component: () => import("@/components/icons/FavoriteWhiteIcon"),
      },
      help: {
        component: () => import("@/components/icons/HelpIcon"),
      },
      information: {
        component: () => import("@/components/icons/InformationIcon"),
      },
      link: {
        component: () => import("@/components/icons/LinkIcon"),
      },
      list: {
        component: () => import("@/components/icons/ListIcon"),
      },
      lock: {
        component: () => import("@/components/icons/LockIcon"),
      },
      logout: {
        component: () => import("@/components/icons/LogoutIcon"),
      },
      mail: {
        component: () => import("@/components/icons/MailIcon"),
      },
      menu: {
        component: () => import("@/components/icons/MenuIcon"),
      },
      move: {
        component: () => import("@/components/icons/MoveIcon"),
      },
      participants: {
        component: () => import("@/components/icons/ParticipantsIcon"),
      },
      plus: {
        component: () => import("@/components/icons/PlusIcon"),
      },
      profile: {
        component: () => import("@/components/icons/ProfileIcon"),
      },
      replay: {
        component: () => import("@/components/icons/ReplayIcon"),
      },
      search: {
        component: () => import("@/components/icons/SearchIcon"),
      },
      settings: {
        component: () => import("@/components/icons/SettingsIcon"),
      },
      upload: {
        component: () => import("@/components/icons/UploadIcon"),
      },
      video_call: {
        component: () => import("@/components/icons/VideoCallIcon"),
      },
    },
  },
  options: {
    customProperties: true
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#82f09b',
        accent: '#5acd9b',
        error: '#c63f58',
        success: '#36a44f',
      },
    }
  },
})
