const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function getRedirectUrl() {
  const { electron, location } = window;
  const { origin } = location;
  return electron
    ? origin + '/index.html#/login?q'
    : origin + '/login'
}

export function getServerBaseUrl(server) {
  let scheme = 'https://'
  if (server.match(/^localhost(:[0-9]+)$/)) {
    scheme = 'http://'
  }
  return scheme + server
}

export function getWsBaseUrl(server) {
  let scheme = 'wss://'
  if (server.match(/^localhost(:[0-9]+)$/)) {
    scheme = 'ws://'
  }
  return scheme + server + '/api/ws'
}

export function isElectron() {
  return window.electron !== undefined
}

export function isEmail(str) {
  if (!str) {
    return false
  }
  return EMAIL_REGEX.test(str.toLowerCase())
}
