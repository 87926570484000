<template>
  <v-main>
    <v-container
      fill-height
      fluid
      style="background: #000 url('background.svg'); background-size: cover"
    >
      <v-row class="my-5" align="center" justify="center">
        <v-card width="400">
          <v-card-title class="justify-center">
            huddle<span class="primary--text">now</span>
          </v-card-title>
          <v-card-text>
            <h2 class="my-6 text-center">Anmelden</h2>

            <v-form
              class="text-center"
              v-if="!code"
              v-model="form.valid"
              @submit.prevent="submit"
            >
              <v-text-field
                clearable
                required
                class="hide-autofill"
                clear-icon="$delete"
                v-model="workspace"
                label="Workspace"
                :error-messages="form.errorMessages.workspace"
                :loading="validating"
                :rules="form.rules.workspace"
                @input="handleWorkspaceInput"
              ></v-text-field>

              <temeno-button
                action
                large
                class="mt-8"
                type="submit"
                :disabled="!form.valid || loading || validating || connectionError"
                :loading="loading"
              >
                Weiter
              </temeno-button>
            </v-form>

            <p v-else class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </p>

            <p v-if="connectionError" class="text-center mt-5">
              <strong>Fehler:</strong> Der Server ist nicht erreichbar.
              <template v-if="isElectron">
                <br />
                Bitte überprüfen Sie Ihre
                <a href="#" @click="showSettings = true">Verbindungseinstellungen</a>.
              </template>
            </p>
          </v-card-text>
        </v-card>
      </v-row>

      <template v-if="isElectron">
        <temeno-circle-button
          fab
          fixed
          right
          top
          title="Verbindungseinstellungen"
          @click="showSettings = true"
        >
          $settings
        </temeno-circle-button>

        <connection-dialog
          :show="showSettings"
          :showCancelButton="settingsCloseable"
          @cancel="showSettings = false"
          @save="showSettings = false"
        ></connection-dialog>
      </template>
    </v-container>
  </v-main>
</template>

<script>
import _debounce from "lodash/debounce";
import api from "@/api";

export default {
  name: "Login",
  components: {
    ConnectionDialog: () => import("@/components/ConnectionDialog"),
    TemenoButton: () => import("@/components/TemenoButton"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    changeWorkspace: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
    },
    workspaceQueryParameter: {
      type: String,
    },
    redirectPath: {
      type: String,
    }
  },
  data() {
    const workspace = this.workspaceQueryParameter || this.$store.state.connection.workspace;
    return {
      connectionError: false,
      form: {
        errorMessages: {
          workspace: [],
        },
        rules: {
          workspace: [
            (v) =>
              (v && v.length > 0) ||
              "Bitte geben Sie den Namen ihres Workspaces an.",
          ],
        },
        valid: false,
      },
      showSettings: false,
      loading: false,
      validating: false,
      workspace,
      workspaceInitial: workspace,
    };
  },
  computed: {
    isElectron() {
      return window.electron !== undefined;
    },
    settingsCloseable() {
      return this.$store.getters["connection/isServerConfigured"];
    },
  },
  created() {
    this.validateWorkspace = _debounce(this.validateWorkspace, 500);
  },
  mounted() {
    if (this.code) {
      if (this.workspaceQueryParameter) {
        this.$store.dispatch("connection/setWorkspace", this.workspaceQueryParameter);
      }
      api
        .getToken(this.code, this.redirectPath)
        .then((response) => {
          this.$store.dispatch("user/login", response.data).then(() => {
            if (this.redirectPath) {
              this.$router.replace(this.redirectPath);
            } else {
              this.$router.replace({ name: "home" });
            }
          });
        })
        .catch((error) => {
          console.error(error);
          this.$router.replace({ name: "login" });
        });
    } else {
      this.handleWorkspaceInput();
    }
    if (!this.$store.getters["connection/isServerConfigured"]) {
      // open settings dialog directly
      this.showSettings = true;
    }
  },
  methods: {
    handleWorkspaceInput() {
      if (this.workspace) {
        this.validating = true;
        this.validateWorkspace();
      } else {
        this.form.errorMessages.workspace = [];
      }
    },
    submit() {
      this.loading = true;
      this.$store.dispatch("connection/setWorkspace", this.workspace);
      api.login(this.redirectPath);
    },
    validateWorkspace() {
      const { workspace } = this;
      api
        .workspaceExists(workspace)
        .then((response) => {
          this.connectionError = false;
          const { exists } = response.data;
          if (exists && workspace !== "master") {
            this.form.errorMessages.workspace = [];
            // auto submit if we do not want to change our workspace
            if (this.workspaceInitial && !this.changeWorkspace) {
              this.submit();
            }
          } else {
            this.form.errorMessages.workspace = [
              "Dieser Workspace existiert leider nicht.",
            ];
          }
        })
        .catch((error) => {
          console.error("Error validating workspace", error);
          this.connectionError = true;
          this.form.errorMessages.workspace = [];
        })
        .finally(() => {
          this.validating = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.hide-autofill input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 200px $color-background-lighten-1 inset;
  -webkit-text-fill-color: #fff;
}
</style>
