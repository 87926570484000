import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import qs from 'qs'
import store from '@/store'
import { getRedirectUrl, isElectron } from '@/functions'

const client_id = 'huddlenow-desktop'
const redirect_uri = getRedirectUrl()

function refreshToken() {
  const { refreshToken } = store.state.user
  const { workspace } = store.state.connection
  const url = store.getters['connection/serverBaseUrl'] + `/api/workspaces/${workspace}/token`
  const data = {
    grant_type: 'refresh_token',
    client_id,
    refresh_token: refreshToken,
  }
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Huddlenow-Workspace': workspace,
    },
    skipAuthRefresh: true,
  }
  return axios.post(url, qs.stringify(data), config)
}

function refreshAuthLogic(failedRequest) {
  return refreshToken()
    .then(tokenRefreshResponse => {
      const { access_token, refresh_token } = tokenRefreshResponse.data
      store.commit('user/setAccessToken', access_token)
      store.commit('user/setRefreshToken', refresh_token)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + access_token
      return Promise.resolve()
    })
    .catch(() => {
      console.error('error refreshing token')
      store.dispatch('user/logout')
      window.location = isElectron()
        ? 'app://./index.html'
        : '/'
    })
}

createAuthRefreshInterceptor(axios, refreshAuthLogic)

axios.defaults.timeout = 10000 // ms

export default {
  getConfig(server = null) {
    if (!server) {
      server = store.getters['connection/serverBaseUrl']
    }
    const url = server + '/api/config'
    return axios.get(url)
  },
  publishStatus(status) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/users/me/status'
    const data = { status }
    return axios.post(url, data, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },

  // conferences
  createConference(conference) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/conferences'
    return axios.post(url, conference, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  inviteForConference(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/conferences/${id}/actions/invite`
    return axios.post(url, null, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  createShareLink(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/conferences/${id}/share-link`
    return axios.put(url, null, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  deleteShareLink(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/conferences/${id}/share-link`
    return axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  accessConference(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/conferences/${id}/access`
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  accessConferenceViaShareLink(uuid) {
    const url = store.getters['connection/serverBaseUrl'] + `/api/conferences/share-links/${uuid}/access`
    return axios.get(url, { skipAuthRefresh: true })
  },

  // calendar
  getCalendarEvents(start, end) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/calendar/events'
    const params = {
      start,
      end,
    }
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
      params,
    })
  },
  createCalendarEvent(calendarEvent) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/calendar/events'
    return axios.post(url, calendarEvent, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  updateCalendarEvent(calendarEvent) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const { id } = calendarEvent
    const url = store.getters['connection/serverBaseUrl'] + `/api/calendar/events/${id}`
    return axios.put(url, calendarEvent, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  deleteCalendarEvent(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/calendar/events/${id}`
    return axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },

  // busy periods
  getBusyPeriods(userId, start, end) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/calendar/busy-periods/${userId}`
    const params = {
      start,
      end,
    }
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
      params,
    })
  },

  // free periods
  getFreePeriods(userIds, date, duration, considerParticipantWorkingTimes = true) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/calendar/free-periods'
    const params = {
      userId: userIds,
      date,
      duration,
      considerParticipantWorkingTimes,
    }
    const paramsSerializer = (p) => qs.stringify(p, { arrayFormat: 'repeat' })
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
      params,
      paramsSerializer,
    })
  },

  // preflight
  preflight(calendarEvent) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/calendar/preflight'
    return axios.post(url, calendarEvent, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },

  // users
  listUsers(query = null) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/users'
    const params = query
      ? { query, }
      : null
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
      params,
    })
  },
  createUser(createUserRequest) {
    const { workspace } = store.state.connection
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/users'
    createUserRequest.clientId = client_id
    createUserRequest.redirectUri = store.getters['connection/serverBaseUrl'] + '/login?workspace=' + encodeURIComponent(workspace)
    return axios.post(url, createUserRequest, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': workspace,
      },
      timeout: 60000, // ms
    })
  },
  deleteUser(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/users/${id}`
    return axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
    })
  },
  getUsersMe() {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/users/me'
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  updateUsersMe(me, image = null) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const method = 'PUT'
    const url = store.getters['connection/serverBaseUrl'] + '/api/users/me'
    const data = new FormData()
    const request = new Blob([JSON.stringify(me)], { type: 'application/json' })
    data.append('request', request)
    if (image) {
      data.append('image', image)
    }
    return axios({
      method,
      url,
      data,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  updatePassword() {
    const { workspace } = store.state.connection
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/users/me/actions/update-password'
    const updatePasswordRequest = {
      clientId: client_id,
      redirectUri: store.getters['connection/serverBaseUrl'] + '/login?workspace=' + encodeURIComponent(workspace),
    }
    return axios.post(url, updatePasswordRequest, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': workspace,
      },
      timeout: 60000, // ms
    })
  },

  // contacts
  listContacts(query = null) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/contacts'
    const params = query
      ? { query, }
      : null
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
      params,
    })
  },
  createContact(contact) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/contacts'
    return axios.post(url, contact, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  updateContact(contact) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const { id } = contact
    const url = store.getters['connection/serverBaseUrl'] + `/api/contacts/${id}`
    return axios.put(url, contact, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  deleteContact(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/contacts/${id}`
    return axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },

  // groups
  listGroups() {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/groups'
    return axios.get(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
    })
  },
  createGroup(group) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + '/api/groups'
    return axios.post(url, group, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  updateGroup(group) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const { id } = group
    const url = store.getters['connection/serverBaseUrl'] + `/api/groups/${id}`
    return axios.put(url, group, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },
  deleteGroup(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/groups/${id}`
    return axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      }
    })
  },

  // favorites
  addGroupFavorite(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/favorites/groups/${id}`
    return axios.put(url, null, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
    })
  },
  deleteGroupFavorite(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/favorites/groups/${id}`
    return axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
    })
  },
  addUserFavorite(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/favorites/users/${id}`
    return axios.put(url, null, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
    })
  },
  deleteUserFavorite(id) {
    const { accessToken } = store.state.user
    if (!accessToken) {
      return Promise.reject(new Error('Missing access token'))
    }
    const url = store.getters['connection/serverBaseUrl'] + `/api/favorites/users/${id}`
    return axios.delete(url, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Huddlenow-Workspace': store.state.connection.workspace,
      },
    })
  },

  // workspaces
  workspaceExists(name) {
    const url = store.getters['connection/serverBaseUrl'] + `/api/workspaces/${name}`
    return axios.get(url)
  },
  login(redirectPath) {
    const { workspace } = store.state.connection
    let redirect_uri_encoded = encodeURIComponent(redirect_uri)
    if (redirectPath) {
      redirect_uri_encoded += encodeURIComponent('?redirectPath=' + encodeURIComponent(redirectPath))
    }
    const response_mode = isElectron()
      ? 'fragment'
      : 'query'
    const url = store.getters['connection/serverBaseUrl'] + `/api/workspaces/${workspace}/login?client_id=${client_id}&redirect_uri=${redirect_uri_encoded}&response_mode=${response_mode}`
    window.location = url
  },
  getToken(code, redirectPath) {
    const { workspace } = store.state.connection
    const url = store.getters['connection/serverBaseUrl'] + `/api/workspaces/${workspace}/token`
    let redirect_uri = getRedirectUrl()
    if (redirectPath) {
      redirect_uri += '?redirectPath=' + encodeURIComponent(redirectPath)
    }
    const data = {
      client_id,
      code,
      grant_type: 'authorization_code',
      redirect_uri,
    }
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Huddlenow-Workspace': workspace,
      },
    }
    return axios.post(url, qs.stringify(data), config)
  },
  refreshToken,
  logout() {
    const { workspace } = store.state.connection
    const redirect_uri_encoded = encodeURIComponent(redirect_uri)
    const url = store.getters['connection/serverBaseUrl'] + `/api/workspaces/${workspace}/logout?redirect_uri=${redirect_uri_encoded}`
    window.location = url
  },
}
