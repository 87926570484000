import { calendarIntervalOptions, calendarWeekdayOptions } from "@/const";

const defaultCalendarIntervalOption = calendarIntervalOptions.filter(option => option.default)[0];
const defaultCalendarWeekdayOption = calendarWeekdayOptions.filter(option => option.default)[0];

export default {
  namespaced: true,
  state: () => ({
    calendarInterval: defaultCalendarIntervalOption,
    calendarWeekdays: defaultCalendarWeekdayOption,
    startWithAudioMuted: false,
    startWithVideoMuted: false,
  }),
  mutations: {
    setCalendarInterval(state, calendarInterval) {
      state.calendarInterval = calendarInterval
    },
    setCalendarWeekdays(state, calendarWeekdays) {
      state.calendarWeekdays = calendarWeekdays
    },
    update(state, settings) {
      state.startWithAudioMuted = settings.startWithAudioMuted
      state.startWithVideoMuted = settings.startWithVideoMuted
    },
  },
  actions: {
    update({ commit }, settings) {
      commit('update', settings)
    },
  },
}
