<template>
  <v-main>
    <conference-frame
      v-if="!error"
      :config="config"
      @readyToClose="onConferenceEnded"
      @suspendDetected="onConferenceEnded"
      @videoConferenceJoined="onConferenceStarted"
    ></conference-frame>
    <v-container v-else fill-height>
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <v-alert dark prominent color="error">
            <p>{{ $t(`errors.${error}`) }}</p>
            <v-btn outlined color="white" class="mr-2" href="https://huddlenow.net/kontakt" target="_blank" v-if="error === 'workspace_expired'">
              Jetzt Workspace freischalten
            </v-btn>
            <v-btn outlined color="white" @click.prevent="$router.replace({ name: 'home' })">
              {{ $t("back") }}
            </v-btn>
          </v-alert>
        </v-col>
      </v-row>
      <v-overlay></v-overlay>
    </v-container>
  </v-main>
</template>

<script>
import { AVAILABLE, BUSY } from "@/status";
import ConferenceFrame from "@/components/ConferenceFrame";
import api from "@/api";

export default {
  name: "Conference",
  components: {
    ConferenceFrame,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      config: null,
      error: null,
    };
  },
  mounted() {
    this.updateConfig();
    if (window.electron) {
      window.electron.ipcRenderer.on("window-closed", () => {
        this.config = null; // this terminates the conference
        this.$router.push({ name: "home" });
      });
    }
  },
  watch: {
    id() {
      this.updateConfig();
    },
  },
  methods: {
    updateConfig() {
      const {
        startWithAudioMuted,
        startWithVideoMuted,
      } = this.$store.state.settings;
      api
        .accessConference(this.id)
        .then((response) => {
          const { conference, domain, umaTicket } = response.data;
          const options = {
            roomName: conference.id,
            configOverwrite: {
              prejoinPageEnabled: false,
              startWithAudioMuted,
              startWithVideoMuted,
            },
            jwt: umaTicket,
          };
          const subject = conference.subject;
          this.config = { domain, options, subject };
        })
        .catch((error) => {
          const response = error.response;
          if (response && response.status === 403) {
            if (response.data && response.data.code && response.data.code === "WORKSPACE_EXPIRED") {
              this.error = "workspace_expired";
            } else {
              this.error = "forbidden";
            }
          } else if (response && response.status === 404) {
            this.error = "not_found";
          } else if (response && response.status === 429) {
            this.error = "license_limit";
          } else {
            console.error(error);
            this.error = "unknown";
          }
        });
    },
    onConferenceStarted() {
      this.$store.dispatch("user/setStatus", BUSY);
    },
    onConferenceEnded() {
      this.$store.dispatch("user/setStatus", AVAILABLE);
      const router = this.$router;
      setTimeout(() => {
        router.push({ name: "home" });
      }, 1000);
    },
  },
  i18n: {
    messages: {
      de: {
        back: "Zurück",
        errors: {
          forbidden:
            "Sie haben leider nicht die Berechtigung, dieser Konferenz beizutreten.",
          license_limit:
            "Die maximale Anzahl an Teilnehmern ist ausgeschöpft. " +
            "Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. " +
            "Falls das Problem weiterhin auftritt wenden Sie sich bitte an einen Administrator.",
          not_found:
            "Diese Konferenz existiert leider nicht (mehr).",
          unknown:
            "Ein unbekannter Fehler ist aufgetreten. " +
            "Bitte melden Sie sich erneut an und versuchen es erneut.",
          workspace_expired:
            "Der kostenlose Testzeitraum Ihres huddlenow Workspace ist leider abgelaufen.",
        },
      },
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 550px;
}
.v-alert {
  padding: 32px;
  z-index: 10;
}
</style>
