export const calendarIntervalOptions = [
  {
    default: true,
    name: "coreTime",
    count: 10,
    first: 8,
    minutes: 60,
  },
  {
    name: "fullDay",
    count: 24,
    first: 0,
    minutes: 60,
  },
];

export const calendarWeekdayOptions = [
  {
    default: true,
    name: "coreTime",
    value: [1, 2, 3, 4, 5],
  },
  {
    name: "fullWeek",
    value: [1, 2, 3, 4, 5, 6, 0],
  },
];
