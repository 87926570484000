<template>
  <v-main>
    <conference-frame
      v-if="!error"
      :config="config"
      @readyToClose="onConferenceEnded"
      @suspendDetected="onConferenceEnded"
    ></conference-frame>
    <v-container v-else fill-height>
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <v-alert dark prominent color="error">
            <p>{{ $t(`errors.${error}`) }}</p>
          </v-alert>
        </v-col>
      </v-row>
      <v-overlay></v-overlay>
    </v-container>
  </v-main>
</template>

<script>
import ConferenceFrame from "@/components/ConferenceFrame";
import api from "@/api";

export default {
  name: "GuestConference",
  components: {
    ConferenceFrame,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      config: null,
      error: null,
    };
  },
  mounted() {
    this.updateConfig();
  },
  watch: {
    uuid() {
      this.updateConfig();
    },
  },
  methods: {
    updateConfig() {
      api
        .accessConferenceViaShareLink(this.uuid)
        .then((response) => {
          const { conference, domain, personalized, umaTicket } = response.data;
          const options = {
            roomName: conference.id,
            configOverwrite: {
              disableInviteFunctions: true,
              prejoinPageEnabled: !personalized, // enable prejoin page only for non-personalized JWTs
            },
            interfaceConfigOverwrite: {
              HIDE_INVITE_MORE_HEADER: true,
              TOOLBAR_BUTTONS: [
                'microphone',
                'camera',
                'desktop',
                'fullscreen',
                'fodeviceselection',
                'hangup',
                'profile',
                'chat',
                'settings',
                'raisehand',
                'videoquality',
                'filmstrip',
                'feedback',
                'stats',
                'shortcuts',
                'tileview',
                'download',
                'help',
            ],
            },
            jwt: umaTicket,
          };
          const subject = conference.subject;
          this.config = { domain, options, subject };
        })
        .catch((error) => {
          const response = error.response;
          if (response && response.status === 403) {
            if (response.code && response.code === "WORKSPACE_EXPIRED") {
              this.error = "workspace_expired";
            } else {
              this.error = "forbidden";
            }
          } else if (response && response.status === 404) {
            this.error = "not_found";
          } else if (response && response.status === 429) {
            this.error = "license_limit";
          } else {
            console.error(error);
            this.error = "unknown";
          }
        });
    },
    onConferenceEnded() {
      setTimeout(() => {
        window.location.replace("https://huddlenow.net/");
      }, 3000);
    },
  },
  i18n: {
    messages: {
      de: {
        errors: {
          forbidden:
            "Sie haben leider nicht die Berechtigung, dieser Konferenz beizutreten.",
          license_limit:
            "Die maximale Anzahl an Teilnehmern ist ausgeschöpft. " +
            "Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
          not_found:
            "Diese Konferenz existiert leider nicht (mehr).",
          unknown:
            "Ein unbekannter Fehler ist aufgetreten. " +
            "Bitte melden Sie sich erneut an und versuchen es erneut.",
          workspace_expired:
            "Dieser huddlenow Workspace steht momentan leider nicht zur Verfügung.",
        },
      },
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 550px;
}
.v-alert {
  padding: 32px;
  z-index: 10;
}
</style>
