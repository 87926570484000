<template>
  <v-app>
    <template v-if="showAppBar">
      <navigation-drawer
        v-model="showNavigationDrawer"
        @click-show-profile="showProfile = true"
      >
      </navigation-drawer>

      <v-app-bar app :clipped-left="$vuetify.breakpoint.lgAndUp">
        <v-app-bar-nav-icon
          @click.stop="showNavigationDrawer = !showNavigationDrawer"
        >
          <v-icon>$menu</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>
          <router-link
            style="font-weight: bold; text-decoration: none"
            :to="{ name: 'home' }"
          >
            <span class="white--text">huddle</span>now
          </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-chip
          v-if="!connected"
          class="px-4 mr-2"
          color="error"
          style="font-weight: bold"
        >
          Keine Verbindung zum Server
        </v-chip>

        <temeno-circle-button title="Konferenzkalender" :to="{ name: 'home' }">
          $calendar
        </temeno-circle-button>

        <temeno-circle-button
          title="Externe Kontakte"
          :to="{ name: 'contacts' }"
        >
          $contacts
        </temeno-circle-button>

        <temeno-circle-button
          title="Ad-hoc-Konferenz starten"
          @click="showAdHoc = true"
        >
          $video_call
        </temeno-circle-button>

        <temeno-circle-button
          title="Einstellungen"
          @click="showSettings = true"
        >
          $settings
        </temeno-circle-button>

        <temeno-circle-button title="abmelden" @click="logout">
          $logout
        </temeno-circle-button>
      </v-app-bar>

      <settings-dialog
        :show="showSettings"
        @cancel="showSettings = false"
        @save="showSettings = false"
      ></settings-dialog>

      <ad-hoc-dialog
        :show="showAdHoc"
        @cancel="showAdHoc = false"
        @submit="showAdHoc = false"
      ></ad-hoc-dialog>

      <profile-dialog
        :show="showProfile"
        @cancel="showProfile = false"
        @save="showProfile = false"
      ></profile-dialog>
    </template>

    <router-view></router-view>

    <snackbar-queue></snackbar-queue>

    <template v-if="!isElectron">
      <download-ad v-if="showAppBar"></download-ad>

      <incoming-call-dialog
        :invitation="mostRecentInvitation"
      ></incoming-call-dialog>
    </template>
  </v-app>
</template>

<script>
import api from "@/api";
import { AVAILABLE, IDLE } from "@/status";

function showAppBar(route) {
  const { name } = route;
  if (name === "conference" || name === "guestConference" || name === "login") {
    return false;
  } else {
    return true;
  }
}

export default {
  name: "App",
  components: {
    AdHocDialog: () => import("@/components/AdHocDialog.vue"),
    DownloadAd: () => import("@/components/DownloadAd.vue"),
    IncomingCallDialog: () => import("@/components/IncomingCallDialog"),
    NavigationDrawer: () => import("@/components/nav/NavigationDrawer"),
    ProfileDialog: () => import("@/components/ProfileDialog"),
    SettingsDialog: () => import("@/components/SettingsDialog"),
    SnackbarQueue: () => import("@/components/SnackbarQueue"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  data() {
    return {
      showAdHoc: false,
      showAppBar: showAppBar(this.$router.currentRoute),
      showIncomingCall: false,
      showNavigationDrawer: null,
      showProfile: false,
      showSettings: false,
    };
  },
  computed: {
    connected() {
      return this.$store.state.connection.connected;
    },
    invitations() {
      return this.$store.state.invitations;
    },
    isElectron() {
      return window.electron !== undefined;
    },
    mostRecentInvitation() {
      const keys = Object.keys(this.invitations);
      if (keys.length > 0) {
        const id = keys[0];
        return this.invitations[id];
      } else {
        return null;
      }
    },
    userLoggedIn() {
      return this.$store.state.user.loggedIn;
    },
  },
  watch: {
    $route(to) {
      this.showAppBar = showAppBar(to);
    },
    mostRecentInvitation(invitation) {
      if (invitation) {
        this.showNotification(invitation);
      }
    },
  },
  mounted() {
    if (window.electron) {
      const { ipcRenderer } = window.electron;

      // handle accept / decline messages from notification window
      const { dispatch } = this.$store;
      ipcRenderer.on("accept-invitation", (_event, invitation) => {
        dispatch("invitations/accept", invitation);
      });
      ipcRenderer.on("decline-invitation", (_event, invitation) => {
        dispatch("invitations/decline", invitation);
      });

      // handle protocol data message
      ipcRenderer.on("protocol-data-msg", (_event, inputUrl) => {
        // Remove trailing slash if one exists.
        if (inputUrl.substr(-1) === "/") {
          inputUrl = inputUrl.substr(0, inputUrl.length - 1);
        }
        const id = Number(inputUrl);
        if (!Number.isNaN(id)) {
          this.$router.push({
            name: "conference",
            params: {
              id,
            },
          });
        }
      });

      // send ready notification to main process
      ipcRenderer.send("renderer-ready");
    } else {
      Notification.requestPermission().then((result) => {
        console.log("notification request permission result", result);
      });
    }
    if (this.userLoggedIn) {
      this.$store.dispatch("user/setStatus", AVAILABLE);
    }
    // TODO user status updates via web sockets
    setInterval(() => {
      this.$store.dispatch("users/load");
    }, 60000); // ms
  },
  beforeDestroy() {
    if (this.userLoggedIn) {
      this.$store.dispatch("user/setStatus", IDLE);
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
      this.showNavigationDrawer = null;
      api.logout();
    },
    showNotification(invitation) {
      const { avatar, name } = invitation.invitee;
      const { subject } = invitation.conference;
      // HTML5 Notification API
      const notification = new Notification(`Eingehender Anruf von ${name}`, {
        body: subject,
        icon: avatar,
      });

      notification.onclick = () => {
        window.focus();
      };
    },
  },
};
</script>

<style>
html {
  overflow-y: auto !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  user-select: none;
}

.v-btn,
.v-tab {
  text-transform: none !important;
}

/* calendar */
.theme--dark.v-calendar .v-calendar-daily_head-weekday {
  color: #fff;
  text-transform: none;
}
.theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label,
.theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday {
  color: #14415F;
}
.theme--dark.v-calendar
  .v-calendar-daily_head-day
  .v-calendar-daily_head-day-label
  .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #0b2d43 !important;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  margin: 5px 0 6px 0;
  width: 40px;
}
.theme--dark.v-calendar
  .v-calendar-daily_head-day.v-present
  .v-calendar-daily_head-day-label
  .v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #82f09b !important;
  color: #14415f;
}
.theme--dark.v-calendar .v-event-timed {
  user-select: none;
}

/* navigation drawer */
.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background-color: #14415f !important;
}
.theme--dark.v-navigation-drawer .v-list-item::before {
  content: none !important;
}
.theme--dark.v-navigation-drawer .v-list-item:hover,
.theme--dark.v-navigation-drawer
  .v-list-item:not(.no-hover):not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: #82f09b !important;
}
.theme--dark.v-navigation-drawer .v-divider {
  border-color: #82f09b !important;
}
.theme--dark.v-navigation-drawer .v-list-item__title.title {
  line-height: 1.2;
}

/* text fields */
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(35, 100, 150, 0.7);
}
.theme--dark.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: rgb(35, 100, 150);
}

/* tables */
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th,
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(35, 100, 150, 0.7);
}
.theme--dark.v-data-table .v-data-footer {
  border-top: thin solid rgba(35, 100, 150, 0.7);
}

/* scrollbars */
::-webkit-scrollbar {
  background: #0a283c;
  width: 10px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-track-piece {
  background: none;
}
</style>
