import Vue from 'vue'
import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    // group IDs are added as keys
  }),
  mutations: {
    setAll(state, groups) {
      const groupIds = groups.map(g => g.id)
      // delete non-existent groups
      Object.keys(state)
        .filter(id => !groupIds.includes(id))
        .forEach(id => Vue.delete(state, id))
      // set groups
      groups.forEach(group => {
        const { id } = group
        Vue.set(state, id, group)
      })
      console.log(`successfully loaded ${Object.keys(state).length} group(s)`)
    },
    setFavorite(state, { id, value }) {
      state[id].favorite = value
    },
  },
  actions: {
    async load({ commit }) {
      console.log('loading groups')
      api.listGroups().then((response) => {
        commit('setAll', response.data)
      })
    },
    async save({ dispatch }, group) {
      const { id } = group
      if (id) {
        api.updateGroup(group).then(() => {
          dispatch('load')
        });
      } else {
        api.createGroup(group).then(() => {
          dispatch('load')
        });
      }
    },
    async delete({ dispatch }, id) {
      api.deleteGroup(id).then(() => {
        dispatch('load')
      });
    },
    async toggleFavorite({ commit }, group) {
      const { id } = group
      const value = !group.favorite
      const setFavorite = () => commit('setFavorite', { id, value })
      if (value) {
        api.addGroupFavorite(id).then(setFavorite)
      } else {
        api.deleteGroupFavorite(id).then(setFavorite)
      }
    },
  },
  getters: {
    all: (state) => Object.values(state),
    favorites: (_state, getters) => getters.all.filter(group => group.favorite),
  },
}
