<template>
  <v-main>
    <v-toolbar flat>
      <temeno-circle-button small class="mr-1" title="eine Woche zurück" @click="$refs.calendar.prev()">
        $arrow_left
      </temeno-circle-button>

      <temeno-button small class="mr-1" @click="focus = ''">
        Heute
      </temeno-button>

      <temeno-circle-button small title="eine Woche vor" @click="$refs.calendar.next()">
        $arrow_right
      </temeno-circle-button>

      <v-spacer></v-spacer>

      <v-toolbar-title v-if="$refs.calendar" class="mr-3">
        {{ $refs.calendar.title }}
      </v-toolbar-title>

      <v-chip small> KW {{ calendarWeek }} </v-chip>

      <v-spacer></v-spacer>

      <calendar-days-menu buttonCssClass="mr-2"></calendar-days-menu>

      <calendar-interval-menu></calendar-interval-menu>
    </v-toolbar>

    <v-calendar
      v-model="focus"
      ref="calendar"
      type="week"
      locale="de"
      style="height: calc(100vh - 128px)"
      :event-color="getEventColor"
      :event-text-color="getEventTextColor"
      :events="events"
      :shortWeekdays="false"
      :weekdays="$store.state.settings.calendarWeekdays.value"
      :first-interval="calendarInterval.first"
      :interval-count="calendarInterval.count"
      :interval-format="intervalFormat"
      :interval-height="intervalHeight"
      :interval-minutes="calendarInterval.minutes"
      @click:event="onClickEvent"
      @click:time="onClickTime"
      @change="onChange"
    >
      <template v-slot:event="{ event }">
        <div class="px-2 py-1">
          <strong>{{ event.conference.subject }}</strong>
          <br />
          {{ getEventTime(event) }}
          <v-icon
            small
            style="position: absolute; top: 3px; right: 4px"
            v-if="event.recurrence"
            :color="getEventTextColor(event)"
          >
            mdi-update
          </v-icon>
        </div>
      </template>
      <template v-slot:day-body="{ date }">
        <div
          v-if="date === today"
          class="v-current-time"
          :style="{ top: nowY }"
        ></div>
      </template>
    </v-calendar>

    <calendar-event-editor
      v-model="calendarEventEditor.event"
      :show="calendarEventEditor.show"
      @cancel="onCalendarEventEditorCancel"
      @delete="onCalendarEventEditorDelete"
      @input="onCalendarEventEditorInput"
    ></calendar-event-editor>
  </v-main>
</template>

<script>
import moment from "moment";
import api from "@/api";

function hasParticipants(calendarEvent) {
  if (calendarEvent && calendarEvent.conference && calendarEvent.conference.participants) {
    return calendarEvent.conference.participants.length > 0;
  }
}

export default {
  name: "Home",
  components: {
    CalendarDaysMenu: () => import("@/components/CalendarDaysMenu"),
    CalendarEventEditor: () => import("@/components/CalendarEventEditor"),
    CalendarIntervalMenu: () => import("@/components/CalendarIntervalMenu"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
    TemenoButton: () => import("@/components/TemenoButton"),
  },
  data() {
    return {
      calendarEventEditor: {
        event: null,
        show: false,
      },
      events: [],
      focus: "",
      intervalHeight: 48,
      period: {
        start: null,
        end: null,
      },
      ready: false,
      refreshInterval: {
        id: null,
        duration: 60000,
      },
      today: new Date().toISOString().substring(0, 10),
    };
  },
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    calendarInterval() {
      return this.$store.state.settings.calendarInterval;
    },
    calendarWeek() {
      if (this.period.start) {
        return moment(this.period.start).week();
      } else {
        return null;
      }
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
  },
  watch: {
    calendarInterval() {
      this.updateIntervalHeight();
    },
  },
  created() {
    window.addEventListener("resize", this.updateIntervalHeight);
    this.$store.dispatch("groups/load");
    this.$store.dispatch("users/load");
  },
  mounted() {
    this.updateIntervalHeight();
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
    this.refreshInterval.id = setInterval(
      this.loadEvents,
      this.refreshInterval.duration
    );
  },
  destroyed() {
    window.removeEventListener("resize", this.updateIntervalHeight);
    clearInterval(this.refreshInterval.id);
  },
  methods: {
    getEventColor(event) {
      const iAm = this.$store.state.user.id;
      const { owner } = event.conference;
      if (iAm === owner) {
        return event.color || "primary";
      }
      return "grey lighten-2";
    },
    getEventTextColor(event) {
      const eventColor = this.getEventColor(event);
      switch (eventColor) {
        case "#048a81": // Celadon Green
        case "#bf4e30": // Vermilion
        case "#8a89c0": // Blue Bell
          return "white";
        case "#82f09b": // Light Green
        case "#54c6eb": // Vivid Sky Blue
        case "#cda2ab": // Tuscany
        case "primary":
        case "grey lighten-2":
        default:
          return "black";
      }
    },
    getEventTime(event) {
      return (
        moment(event.start).format("HH:mm") +
        "–" +
        moment(event.end).format("HH:mm")
      );
    },
    intervalFormat(interval) {
      return interval.time;
    },
    onCalendarEventEditorCancel() {
      this.calendarEventEditor.event = null;
      this.calendarEventEditor.show = false;
    },
    onCalendarEventEditorInput(calendarEvent) {
      if (calendarEvent.id) {
        api.updateCalendarEvent(calendarEvent).then(() => {
          this.loadEvents();
          this.calendarEventEditor.show = false;
          if (hasParticipants(calendarEvent)) {
            this.$store.dispatch(
              "snackbar/addSuccess",
              "Der Termin wurde erfolreich aktualisiert.<br>Die Konferenzteilnehmer erhalten eine aktualisierte Termineinladungper E-Mail, falls sich Änderungen für sie ergeben haben."
            );
          }
        });
      } else {
        api.createCalendarEvent(calendarEvent).then(() => {
          this.loadEvents();
          this.calendarEventEditor.show = false;
          if (hasParticipants(calendarEvent)) {
            this.$store.dispatch(
              "snackbar/addSuccess",
              "Der Termin wurde erfolreich angelegt.<br>Die Konferenzteilnehmer erhalten eine Termineinladung per E-Mail."
            );
          }
        });
      }
    },
    onCalendarEventEditorDelete() {
      const { event } = this.calendarEventEditor;
      api.deleteCalendarEvent(event.id).then(() => {
        this.loadEvents();
        this.calendarEventEditor.show = false;
        if (hasParticipants(event)) {
          this.$store.dispatch(
            "snackbar/addSuccess",
            "Der Termin wurde erfolreich gelöscht.<br>Die Konferenzteilnehmer erhalten eine Terminabsage per E-Mail."
          );
        }
      });
    },
    onChange(event) {
      this.period.start = moment(event.start.date).toISOString(true);
      this.period.end = moment(event.end.date).endOf("day").toISOString(true);
      this.loadEvents();
    },
    onClickEvent(event) {
      this.calendarEventEditor.event = event.event;
      this.calendarEventEditor.show = true;
    },
    onClickTime(event) {
      if (this.calendarEventEditor.show) {
        // calendar event editor is open
        return;
      }
      const { year, month, day, hour, minute } = event;
      const time = new Date(year, month - 1, day, hour, minute).getTime();
      const roundedTime = this.roundTime(time);
      const start = new Date(roundedTime);
      const today = new Date().setHours(0, 0, 0, 0);
      if (start < today) {
        // prevent creating events in the past
        return;
      }
      const end = new Date(roundedTime + 60 * 60 * 1000);
      const color = "#82f09b";
      this.calendarEventEditor.event = {
        conference: {
          subject: "Neue Kalenderkonferenz",
          participants: [],
        },
        start,
        end,
        color,
      };
      this.calendarEventEditor.show = true;
    },
    loadEvents() {
      const { start, end } = this.period;
      if (!start || !end) {
        return;
      }
      api
        .getCalendarEvents(start, end)
        .then((response) => {
          this.events = response.data.map((event) => {
            return {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
              timed: true,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    roundTime(time) {
      const roundDownTime = 15 * 60 * 1000; // 15 minutes
      return time - (time % roundDownTime);
    },
    updateIntervalHeight() {
      const calendarHeaderHeight = 80;
      const calculated =
        (this.$refs.calendar.$el.clientHeight - calendarHeaderHeight) /
        this.$store.state.settings.calendarInterval.count;
      const min = 44;
      this.intervalHeight = Math.max(calculated, min);
    },
    // START current time indicator
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    // END current time indicator
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.theme--dark.v-calendar-daily {
  border-left: none !important;
}

.v-current-time {
  height: 2px;
  background-color: #82f09b;
  box-shadow: 0 0 10px 0 #000;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    background-color: #82f09b;
    box-shadow: 0 0 10px 0 #000;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.v-calendar-daily__day {
  cursor: pointer;

  &.v-past {
    background: #0b2d43;
    cursor: default;
  }
}

.v-calendar-daily_head-weekday {
  margin-bottom: -2px;
}
</style>
