import moment from 'moment'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'
import stomp from '@/stomp'

moment.locale('de')

Vue.config.productionTip = false
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'de',
})

stomp.init()

new Vue({
  el: '#app',
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App),
})
