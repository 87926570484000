import { Client } from '@stomp/stompjs'
import router from '@/router'
import store from '@/store'

let invitationSubscription = null

const client = new Client({
  debug: function (str) {
    if (str !== '>>> PING' && str !== '<<< PONG' && str !== 'Received data') {
      console.log(str)
    }
  },
})

function reconfigureClient() {
  const brokerURL = store.getters['connection/wsUrl']
  if (brokerURL) {
    console.log('Setting STOMP client broker URL to ' + brokerURL)
    client.configure({ brokerURL })
    client.deactivate().then(() => client.activate())
  }
}

function subscribeInvitations() {
  if (!client.connected) return
  const { id } = store.state.user
  if (id) {
    const topic = `/topic/invitations/${id}`
    invitationSubscription = client.subscribe(topic, (message) => {
      const invitation = JSON.parse(message.body)
      const conferenceId = invitation.conference.id
      const { currentRoute } = router
      if (currentRoute && currentRoute.name === 'conference' && currentRoute.params && currentRoute.params.id && currentRoute.params.id === conferenceId) {
        console.debug('Ignoring invitation for already active conference', invitation)
        return
      }
      store.dispatch('invitations/add', invitation)
    })
  }
}

function unsubscribeInvitations() {
  if (client.connected && invitationSubscription) {
    invitationSubscription.unsubscribe()
  }
}

store.subscribe((mutation) => {
  if (mutation.type === 'connection/setServer') {
    reconfigureClient()
  } else if (mutation.type === 'user/loggedIn') {
    subscribeInvitations()
  } else if (mutation.type === 'user/loggedOut') {
    unsubscribeInvitations()
  }
})

client.onConnect = () => {
  console.log('STOMP client connected')
  store.commit('connection/connected', true)
  subscribeInvitations()
}

client.onWebSocketClose = () => {
  console.warn('STOMP client disconnected')
  store.commit('connection/connected', false)
}

export default {
  init() {
    reconfigureClient()
  }
}
