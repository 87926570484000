import store from '@/store'

import Vue from 'vue'
import VueRouter from 'vue-router'

import Contacts from '@/views/Contacts.vue'
import Conference from '@/views/Conference.vue'
import GuestConference from '@/views/GuestConference.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const mode = window.electron
  ? 'hash'
  : 'history'

const router = new VueRouter({
  mode,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: route => ({
        changeWorkspace: route.query.changeWorkspace !== undefined,
        code: route.query.code,
        workspaceQueryParameter: route.query.workspace,
        redirectPath: route.query.redirectPath,
      }),
    },
    {
      path: '/conference/:id(\\d+)',
      name: 'conference',
      component: Conference,
      props: route => ({
        id: Number(route.params.id),
      }),
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
    },
    {
      path: '/go/:uuid',
      name: 'guestConference',
      component: GuestConference,
      props: true,
    },
    {
      path: "*",
      name: 'notFound',
      component: NotFound,
    }
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login' || to.name === 'guestConference' || to.name === 'notFound') {
    next()
  } else {
    const { accessToken } = store.state.user
    if (!accessToken) {
      const query = {}
      if (to.path !== '/') {
        query.redirectPath = to.path
      }
      next({ name: 'login', query })
    } else {
      next()
    }
  }
})

export default router
