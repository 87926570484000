import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import connection from './connection'
import groups from './groups'
import invitations from './invitations'
import settings from './settings'
import snackbar from './snackbar'
import user from './user'
import users from './users'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  modules: ['connection', 'groups', 'settings', 'users'],
  storage: window.localStorage,
})

const vuexSession = new VuexPersist({
  modules: ['user'],
  storage: window.sessionStorage,
})

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin, vuexSession.plugin],
  modules: {
    connection,
    groups,
    invitations,
    settings,
    snackbar,
    user,
    users,
  },
})

export default store
