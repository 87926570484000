<template>
  <v-main>
    <v-card elevation="0">
      <v-card-title>Seite nicht gefunden</v-card-title>
      <v-card-text>
        Es existiert leider keine Seite unter dieser Adresse.
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" link :to="{ name: 'home' }">
          Zur Startseite
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
