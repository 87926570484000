<template>
  <div id="huddlenow">
    <v-overlay v-if="loading">
      <v-progress-circular
        color="accent"
        indeterminate
        :size="75"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import JitsiMeetExternalAPI from "@/external_api";

export default {
  name: "ConferenceFrame",
  props: {
    config: {
      type: Object,
    },
  },
  data() {
    return {
      api: null,
      loading: true,
    };
  },
  watch: {
    config(value) {
      this.terminate(); // terminate ongoing conference first (if any)
      if (value) {
        this.start();
      }
    },
  },
  methods: {
    start() {
      const { domain, options, subject } = this.config;
      options.onload = () => this.loading = false;
      options.parentNode = document.querySelector("#huddlenow");

      console.log(
        `Starting Jitsi conference at ${domain} with options`,
        options
      );

      this.api = new JitsiMeetExternalAPI(domain, options);

      // pass through events
      this.api.on("videoConferenceJoined", () => this.$emit("videoConferenceJoined"));
      this.api.on("readyToClose", () => this.$emit("readyToClose"));
      this.api.on("suspendDetected", () => this.$emit("suspendDetected"));

      this.api.executeCommand("subject", subject);

      if (window.electron) {
        const {
          setupScreenSharingRender,
          // setupAlwaysOnTopRender,
          initPopupsConfigurationRender,
          setupWiFiStats,
          setupPowerMonitorRender,
        } = window.electron.jitsiMeetElectronUtils;
        initPopupsConfigurationRender(this.api);
        setupScreenSharingRender(this.api);
        // TODO setupAlwaysOnTopRender(api)
        setupWiFiStats(this.api.getIFrame());
        setupPowerMonitorRender(this.api);
      }
    },
    terminate() {
      if (this.api) {
        // terminate ongoing conference first
        this.api.executeCommand("hangup");
        document.querySelector("#huddlenow").innerHTML = "";
      }
    },
  },
};
</script>

<style>
#huddlenow {
  height: 100%;
}
</style>
