import Vue from 'vue'
import api from '@/api'

export default {
  namespaced: true,
  state: () => ({
    // user IDs are added as keys
  }),
  mutations: {
    set(state, users) {
      const userIds = users.map(u => u.id)
      // delete non-existent users
      Object.keys(state)
        .filter(id => !userIds.includes(id))
        .forEach(id => Vue.delete(state, id))
      // set users
      users.forEach(user => {
        const { id } = user
        Vue.set(state, id, user)
      })
      console.log(`successfully loaded ${Object.keys(state).length} user(s)`)
    },
    setFavorite(state, { id, value }) {
      state[id].favorite = value
    },
  },
  actions: {
    load({ commit }) {
      console.log('loading users')
      api.listUsers().then((response) => {
        commit('set', response.data)
      })
    },
    toggleFavorite({ commit }, group) {
      const { id } = group
      const value = !group.favorite
      if (value) {
        api.addUserFavorite(id).then(() => {
          commit('setFavorite', { id, value })
        })
      } else {
        api.deleteUserFavorite(id).then(() => {
          commit('setFavorite', { id, value })
        })
      }
    },
  },
  getters: {
    all: (state) => Object.values(state),
    count: (state) => Object.keys(state).length,
    favorites: (_state, getters) => getters.all.filter(user => user.favorite),
    others: (_state, getters, rootState) => getters.all.filter(user => {
      const myId = rootState.user.id
      return user.id !== myId
    }),
  },
}
